/*
    Document   : override
    Created on : <date>
    Author     : <author>
    Description:
        This is where any of your application specific styles should be included
*/
body {
  --oj-html-font-family: "Oracle Sans";

  &.os-disable-horizontal-scroll {
    overflow-x: hidden;
  }
}

body .oj-dialog .oj-dialog-footer {
    display: none;
}

body .oda-chat-wrapper {
    z-index: 500 !important;
}

body .oda-chat-wrapper .read-more {
    color: RGB(var(--oj-palette-neutral-rgb-0));
    background-color: RGB(var(--oj-palette-neutral-rgb-190));
    font-size: var(--oj-typography-body-xl-font-size);
    padding: var(--oj-button-text-to-edge-padding);
}

body .oda-chat-wrapper .read-more:hover {
    color: RGB(var(--oj-palette-neutral-rgb-0));
    background-color: RGB(var(--oj-palette-neutral-rgb-60));
}

body .oda-chat-wrapper .more {
    display: none;
}
